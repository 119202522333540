import { FC } from "react";
import {
  Show,
  ShowProps,
  RaRecord,
  TextField,
  ReferenceField,
  Datagrid,
  SimpleShowLayout,
  ArrayField,
  FunctionField,
  DateField,
  useRecordContext,
} from "react-admin";
import { GroupField } from "./GroupField";
import { EmailPerson } from "./PersonField";
import { Link as RouterLink } from "react-router-dom";
import { Link, FormControl } from "@mui/material";

interface SubscribedGroupFieldProps {
  record?: RaRecord;
}

const GroupTransferField: FC<SubscribedGroupFieldProps> = (props) => {
  const record = useRecordContext();

  return record?.groupId ? (
    <ReferenceField
      label="Group"
      record={record}
      source="groupId"
      reference="groups"
      link="show"
      {...props}
    >
      <GroupField />
    </ReferenceField>
  ) : (
    <FormControl>
      <Link
        sx={{ margin: "4px" }}
        component={RouterLink}
        to={"/AssignSubscription/" + record?.id}
      >
        Assign To Group
      </Link>
    </FormControl>
  );
};

export const StripeLink = () => {
  const record = useRecordContext();
  return record ? (
    <FormControl>
      <Link
        sx={{
          backgroundColor: "rgb(99, 91, 255)",
          color: "white",
          borderRadius: "40px",
          padding: "4px 12px",
          "&:hover": {
            color: "white",
          },
        }}
        target="_blank"
        href={`https://dashboard.stripe.com/subscriptions/${record.id}`}
      >
        Manage on Stripe &gt;
      </Link>
    </FormControl>
  ) : (
    <></>
  );
};

export const SubscriptionShow = (props: ShowProps) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <GroupTransferField />
      <StripeLink />
      <EmailPerson source="customerEmail" label="Owner" />
      <ArrayField source="products" label="Products">
        <Datagrid>
          <TextField source="id" />
          <DateField source="created" />
          <TextField label="Plan" source="planNickname" />
          <FunctionField<{ price: number }>
            label="Price"
            render={(record) => `$${(record?.price ?? 0) / 100}`}
          />
        </Datagrid>
      </ArrayField>
      <TextField source="collection_method" />
      <DateField label="Created" source="startDate" />
      <DateField label="Renews" source="currentPeriodEnd" />
      <TextField source="defaultPaymentMethodId" />
      <TextField source="endedAt" />
      <TextField source="status" />
      <DateField source="trialEnd" />
    </SimpleShowLayout>
  </Show>
);
