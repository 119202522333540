import {
  InfiniteList,
  InfiniteListProps,
  Datagrid,
  TextField,
  DateField,
  useRecordContext,
  EditButton,
  ReferenceField,
} from "react-admin";
import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import GroupField from "../GroupField";

export const AIPromptList = (props: InfiniteListProps) => (
  <InfiniteList {...props}>
    <Datagrid
      rowClick="show"
      bulkActionButtons={false}
      sx={{
        "& .RaDatagrid-rowCell": {
          maxWidth: 300,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        },
      }}
      expand={<AIPreview />}
    >
      <EditButton />
      <DateField showTime={true} source="Timestamp" />
      <ReferenceField source="GroupId" reference="groups" link="show">
        <GroupField />
      </ReferenceField>
      <TextField source="Input" noWrap={true} />
      <TextField source="Existing" noWrap={true} />
      <TextField source="Prompt" noWrap={true} />
      <TextField source="Output" noWrap={true} />
    </Datagrid>
  </InfiniteList>
);

const AIVariable = (props: { source: string }) => {
  const { source } = props;
  const record = useRecordContext();
  return (
    <Box sx={{ whiteSpace: "pre-wrap" }}>
      <Box sx={{ typography: "h6", textAlign: "center" }}>
        {source}
        <IconButton
          sx={{ marginLeft: "24px" }}
          color="primary"
          onClick={() => navigator.clipboard.writeText(record[source])}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </Box>
      {record[source]}
    </Box>
  );
};

const AIPreview = () => {
  const record = useRecordContext();

  return (
    <Box sx={{ display: "flex" }}>
      <Box sx={{ flex: "1" }}>
        <AIVariable source="Input" />
      </Box>
      {!!record.Existing && (
        <Box sx={{ flex: "1" }}>
          <AIVariable source="Existing" />
        </Box>
      )}
      <Box sx={{ flex: "1" }}>
        <AIVariable source="Prompt" />
      </Box>
      <Box sx={{ flex: "1" }}>
        <AIVariable source="Output" />
      </Box>
    </Box>
  );
};
