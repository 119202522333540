import { cloneElement, FC, isValidElement, useCallback, useState } from "react";
import * as React from "react";
import {
  Datagrid,
  DatagridBodyProps,
  DatagridRowProps,
  DatagridProps,
  Loading,
  DatagridCell,
  useResourceContext,
  useDataProvider,
  useRefresh,
  useRecordContext,
  RecordContextProvider,
  useCreatePath,
} from "react-admin";

import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "react-beautiful-dnd";
import { TableBody, TableRow } from "@mui/material";
import classnames from "classnames";
import { useNavigate } from "react-router-dom";

interface DraggableRowProps extends DatagridRowProps {
  index: number;
  id: string;
}

const DraggableRow = (props: DraggableRowProps) => {
  const {
    children,
    className,
    hover,
    id,
    onToggleItem,
    rowClick,
    style,
    selectable,
    index,
    ...rest
  } = props;
  const resource = useResourceContext(props);
  const record = useRecordContext(props);
  const createPath = useCreatePath();
  const nav = useNavigate();

  const handleToggleSelection = useCallback(
    (event: React.MouseEvent) => {
      if (!selectable) return;
      onToggleItem?.(id, event);
      event.stopPropagation();
    },
    [id, onToggleItem, selectable],
  );

  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      if (!rowClick) return;
      event.persist();

      const effect =
        typeof rowClick === "function"
          ? rowClick(id, resource, record)
          : rowClick;
      switch (effect) {
        case "edit":
        case "show":
          nav(createPath({ resource, type: effect, id }));
          return;
        case "toggleSelection":
          handleToggleSelection(event);
          return;
        default:
          {
            const e = await effect;
            if (e) {
              nav(e);
            }
          }
          return;
      }
    },
    [resource, nav, handleToggleSelection, id, record, rowClick, createPath],
  );

  return id ? (
    <Draggable draggableId={id.toString()} index={index}>
      {(provided) => (
        <RecordContextProvider value={record}>
          <TableRow
            ref={provided.innerRef}
            className={className}
            key={id}
            style={style}
            hover={hover}
            onClick={handleClick}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            {...rest}
          >
            {React.Children.map(children, (field, index) =>
              isValidElement(field) ? (
                <DatagridCell
                  key={`${id}-${field.props.source || index}`}
                  className={classnames(`column-${field.props.source}`)}
                  record={record}
                  {...{ field, resource }}
                />
              ) : null,
            )}
          </TableRow>
        </RecordContextProvider>
      )}
    </Draggable>
  ) : (
    <Loading />
  );
};

const DraggableDataGridBody: FC<DatagridBodyProps> = (props) => {
  const {
    children,
    className,
    data,
    expand,
    hasBulkActions,
    hover,
    onToggleItem,
    rowClick,
    rowStyle,
    selectedIds,
    isRowSelectable,
    ...rest
  } = props;

  const resource = useResourceContext(props);
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [loading, setLoading] = useState(false);

  const onDragEnd = (result: DropResult) => {
    if (
      !result.destination ||
      !data ||
      result.source.index === result.destination.index
    ) {
      return;
    }
    setLoading(true);
    console.log(
      `dragEnd ${result.source.index} to  ${result.destination.index}`,
    );
    const id_copy = data.map((item) => item.id);
    const [removed] = id_copy.splice(result.source.index, 1);
    id_copy.splice(result.destination.index, 0, removed);

    let index = result.destination.index;
    let sequence = index > 0 ? data[index - 1].Sequence + 1 : 0;
    const updates = [];
    while (index < id_copy.length) {
      const id = id_copy[index];
      const target = data.find((d) => d.id === id);
      if (target.Sequence >= sequence && index !== result.destination.index) {
        break;
      }
      console.log(`changing id ${id} from ${target.Sequence} to ${sequence}`);
      updates.push(
        dataProvider.update(resource, {
          id,
          data: { Sequence: sequence },
          previousData: target,
        }),
      );
      ++index;
      ++sequence;
    }
    Promise.all(updates).then(() => {
      refresh();
      setLoading(false);
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId={"1"} direction="vertical">
        {(provided) => {
          return (
            <TableBody
              ref={provided.innerRef}
              className={classnames("datagrid-body", className)}
              {...provided.droppableProps}
              {...rest}
            >
              {loading ? (
                <Loading />
              ) : (
                data?.map((item, rowIndex) =>
                  cloneElement(
                    <DraggableRow id={item.id} index={rowIndex}></DraggableRow>,
                    {
                      expand,
                      hasBulkActions,
                      hover,
                      key: item.id,
                      onToggleItem,
                      record: item,
                      resource,
                      rowClick,
                      selectable: !isRowSelectable || isRowSelectable(item),
                      selected: selectedIds?.includes(item.id),
                      style: rowStyle ? rowStyle(item, rowIndex) : null,
                    },
                    children,
                  ),
                )
              )}
              {provided.placeholder}
            </TableBody>
          );
        }}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableDataGrid = (props: DatagridProps) => {
  const { children } = props;
  return (
    <Datagrid {...props} body={<DraggableDataGridBody></DraggableDataGridBody>}>
      {children}
    </Datagrid>
  );
};

export default DraggableDataGrid;
