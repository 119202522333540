import {
  Datagrid,
  DateField,
  SingleFieldList,
  ReferenceManyField,
  Filter,
  TextInput,
  ExportButton,
  TopToolbar,
  useListContext,
  InfiniteList,
  InfiniteListProps,
  CreateButton,
} from "react-admin";
import { PersonField } from "./PersonField";
import { GroupField } from "./GroupField";

const GroupFilter = () => (
  <Filter>
    <TextInput label="Name" source="Name" alwaysOn />
  </Filter>
);

const ListActions = () => {
  const { sort, resource, filterValues, total } = useListContext();
  return (
    <TopToolbar>
      <CreateButton resource={resource}></CreateButton>
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filterValues={filterValues}
      />
    </TopToolbar>
  );
};

export const GroupList = (props: InfiniteListProps) => {
  return (
    <InfiniteList
      {...props}
      filters={<GroupFilter />}
      actions={<ListActions />}
      perPage={25}
      sort={{ field: "Created", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <GroupField label="Group" />
        <DateField source="Created" />
        <ReferenceManyField
          label="owners"
          reference="users"
          target="Owners"
          filter={{ parent: "groups" }}
          sortable={false}
        >
          <SingleFieldList linkType="show">
            <PersonField />
          </SingleFieldList>
        </ReferenceManyField>
      </Datagrid>
    </InfiniteList>
  );
};
