import {
  TextField,
  SelectField,
  NumberField,
  DateField,
  EditProps,
  Edit,
  SimpleForm,
  useRecordContext,
  SimpleShowLayout,
  ReferenceField,
} from "react-admin";

import { Box } from "@mui/material";
import { IconButton } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import GroupField from "../GroupField";

export const AIPromptEdit = (props: EditProps) => {
  return (
    <Edit {...props}>
      <AIPrompt />
    </Edit>
  );
};

const Existing = (props: { source: string }) => {
  const record = useRecordContext();
  return (
    <div>
      <Box typography="h6">
        {props.source}{" "}
        <IconButton
          color="primary"
          onClick={() => navigator.clipboard.writeText(record[props.source])}
        >
          <FileCopyOutlinedIcon />
        </IconButton>
      </Box>
      <Box whiteSpace="pre-wrap" maxHeight={300} overflow="scroll">
        <TextField source={props.source} fullWidth disabled />
      </Box>
    </div>
  );
};

const AIPrompt = () => {
  const record = useRecordContext();

  const rows = record.existing ? "repeat(4,1fr)" : "repeat(3,1fr)";

  return (
    <SimpleForm>
      <SimpleShowLayout>
        <ReferenceField source="GroupId" reference="groups" link="show">
          <GroupField />
        </ReferenceField>
      </SimpleShowLayout>
      <Box
        display="flex"
        border={1}
        width="50%"
        padding="12px"
        marginBottom="48px"
      >
        <SimpleShowLayout>
          <DateField showTime={true} source="Timestamp" />
          <SelectField
            source="Model"
            choices={[
              { id: 3, name: "GPT-4" },
              { id: 4, name: "GPT-4 32K" },
            ]}
          ></SelectField>
        </SimpleShowLayout>
        <SimpleShowLayout>
          <NumberField label="Input Tokens" source="InputTokens" />
          <NumberField label="Output Tokens" source="OutputTokens" />
          <NumberField
            label="Cost"
            source="Cost"
            options={{
              style: "currency",
              currency: "USD",
              minimumFractionDigits: 2,
            }}
          />
        </SimpleShowLayout>
      </Box>
      <Box
        display="grid"
        height="100%"
        gridTemplateColumns="1fr 1fr"
        gridTemplateRows={rows}
        gap="24px"
        gridAutoFlow="column"
      >
        <Existing source="Input" />
        {record.existing && <Existing source="Existing" />}
        <Existing source="Prompt" />
        <Existing source="Output" />
      </Box>
    </SimpleForm>
  );
};
