import { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, Typography } from "@mui/material";
import {
  Title,
  RaRecord,
  useDataProvider,
  Loading,
  Labeled,
  TextField,
  SimpleShowLayout,
  Datagrid,
  Identifier,
  ReferenceManyField,
} from "react-admin";
import { useParams } from "react-router-dom";
import PersonField from "./PersonField";
import GroupField from "./GroupField";

const AssignSubscription = () => {
  const dataProvider = useDataProvider();
  const [sub, setSub] = useState<RaRecord>();
  const [user, setUser] = useState<RaRecord>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error>();
  const { id } = useParams<{ id: string }>();

  const rowClick = (group_id: Identifier) => {
    return `/ConfirmSubscriptionTransfer/${id}/${group_id}`;
  };

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const { data } = await dataProvider.getOne("subscriptions", {
          id,
        });
        setSub(data);
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [dataProvider, id]);

  useEffect(() => {
    const fetchUsers = async () => {
      if (sub) {
        try {
          const { data: users, total } = await dataProvider.getList("users", {
            pagination: { page: 1, perPage: 5 },
            sort: { field: "email", order: "ASC" },
            filter: { email: sub.customerEmail },
          });
          if (total === 0) {
            setError({
              name: "User Not Found",
              message: `User ${sub.customerEmail} not found`,
            });
          } else {
            setUser(users[0]);
          }
        } catch (err) {
          if (err instanceof Error) {
            setError(err);
          }
        }
        setLoading(false);
      }
    };
    fetchUsers();
  }, [dataProvider, sub]);

  if (loading) return <Loading />;
  if (error) {
    if (!sub) {
      throw new Error(`Subscription ${id} Not Found`);
    } else {
      throw new Error(`User ${sub.customerEmail} Not Found`);
    }
  }
  if (!sub || !user) return null;
  return (
    <Card>
      <Title title="Assign Subscription" />
      <CardHeader title="Assign this subscription to an existing group"></CardHeader>
      <CardContent>
        <div>
          <Labeled label="Subscription Id">
            <TextField record={sub} source="id" />
          </Labeled>
        </div>

        <SimpleShowLayout record={user}>
          <PersonField />
          <Typography variant="h5">
            Select a group owned by this user
          </Typography>
          <ReferenceManyField
            label="owned"
            reference="groups"
            target="Owner"
            filter={{ parent: "users" }}
          >
            <Datagrid rowClick={rowClick}>
              <GroupField />
            </Datagrid>
          </ReferenceManyField>
        </SimpleShowLayout>
      </CardContent>
    </Card>
  );
};

export default AssignSubscription;
