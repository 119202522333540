import { useState, Fragment } from "react";
import { Typography, Card, CardContent, CardHeader } from "@mui/material";
import {
  Title,
  Loading,
  Datagrid,
  useGetOne,
  EmailField,
  ListBase,
  ResourceContextProvider,
  Filter,
  TextInput,
  ListToolbar,
  useListContext,
  Button,
  Confirm,
  useUnselectAll,
  useNotify,
  useRedirect,
} from "react-admin";
import { useParams } from "react-router-dom";
import PersonField from "./PersonField";
import GroupField from "./GroupField";
import { useDataProvider } from "react-admin";
import { useMutation } from "react-query";

const UserFilter = () => (
  <Filter>
    <TextInput label="Email" source="Email" alwaysOn />
  </Filter>
);

const AddOwnerButton = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const { selectedIds, data, isLoading } = useListContext();
  const unselectAll = useUnselectAll("users");
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const redirect = useRedirect();
  const dataProvider = useDataProvider();
  const { mutateAsync, isLoading: isAdding } = useMutation((userId) =>
    dataProvider.action("groups", {
      id: groupId,
      action: "AddOwner",
      payload: { id: userId },
    }),
  );

  const handleConfirm = () => {
    const mutations = selectedIds.map((id) => mutateAsync(id));
    Promise.allSettled(mutations).then(() => {
      unselectAll();
      setOpen(false);
      notify("Owners added");
      redirect(`/groups/${groupId}/show/People`);
    });
  };
  if (isLoading) {
    return null;
  }

  return (
    <Fragment>
      <Button
        label={
          selectedIds.length > 1
            ? `ADD ${selectedIds.length} OWNERS`
            : "ADD OWNER"
        }
        onClick={handleClick}
        disabled={selectedIds.length === 0}
      />
      <Confirm
        isOpen={open}
        loading={isAdding}
        title="Add Owners"
        content={
          <div>
            <Typography variant="subtitle1">
              Are you sure you want to add these users as owners?
            </Typography>
            <ul>
              {selectedIds.map((i) => (
                <li key={i}>{data.find((r) => r.id === i).Email}</li>
              ))}
            </ul>
          </div>
        }
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </Fragment>
  );
};

export const AddOwner = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const { data, isLoading, error } = useGetOne("groups", { id: groupId });

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Card>
      <Title title="Add Owner" />
      <CardHeader title="Add another owner to this group"></CardHeader>
      <CardContent>
        <GroupField record={data} />
      </CardContent>
      <ResourceContextProvider value="users">
        <ListBase perPage={25} sort={{ field: "Email", order: "DESC" }}>
          <ListToolbar filters={<UserFilter />} />
          <Datagrid
            rowClick="toggleSelection"
            bulkActionButtons={<AddOwnerButton />}
          >
            <EmailField source="Email" />
            <PersonField />
          </Datagrid>
        </ListBase>
      </ResourceContextProvider>
    </Card>
  );
};
