import { List, ListProps, TextField, Datagrid } from "react-admin";

export const ApplicationList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: "DisplayName", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <TextField source="DisplayName" />
        <TextField source="ClientId" />
        <TextField source="Type" />
      </Datagrid>
    </List>
  );
};
