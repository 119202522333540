import { useEffect, useState } from "react";
import { AzureAuthProvider } from "./azure-ad-authProvider";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  TextField,
} from "@mui/material";
import {
  Loading,
  useGetOne,
  Title,
  useAuthProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { useParams } from "react-router-dom";
import { GroupField } from "./GroupField";

export const GroupCopy = () => {
  const { id } = useParams();
  const groop = useGetOne("groups", { id });
  const authProvider = useAuthProvider() as AzureAuthProvider;

  const [copyStatus, setCopyStatus] = useState(false);
  const [name, setName] = useState("");
  const [copyStyle, setCopyStyle] = useState("StructureOnly");
  const notify = useNotify();
  const redirect = useRedirect();

  const onSubmit = () => {
    setCopyStatus(true);
    const body = new FormData();
    body.append("name", name);
    body.append("copyStyle", copyStyle);

    authProvider
      .getAccessToken(["api://e04ab6e4-8338-42b5-b9e0-7eca9fb58867/Read"])
      .then((token) =>
        fetch(`groups/copy/${id}`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
          },
          body: body,
        }),
      )
      .then((response) => {
        setCopyStatus(false);
        return response.json();
      })
      .then((newGroupId) => redirect(`/groups/${newGroupId}/show`))
      .catch((error) => {
        setCopyStatus(false);
        notify(`Group copy failed: ${error.message}`, { type: "warning" });
      });
  };

  useEffect(() => {
    setName(`${groop.data?.Name} (copy)`);
  }, [groop.data]);

  if (groop.isLoading) {
    return <Loading />;
  }
  if (groop.error) {
    return <p>Copy Failed</p>;
  }

  return (
    <Card style={{ cursor: copyStatus ? "wait" : "default" }}>
      <Title title="Copy Group" />
      <CardHeader title="Create a copy of this group."></CardHeader>
      <CardContent>
        <GroupField record={groop.data} />
        <FormControl>
          <TextField
            sx={{ minWidth: 500 }}
            label="New Group Name"
            inputProps={{ maxLength: 64 }}
            value={name}
            onChange={(e) => setName(e.target.value as string)}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="copy-style">Copy Style</InputLabel>
          <Select
            labelId="copy-style"
            value={copyStyle}
            onChange={(e) => setCopyStyle(e.target.value as string)}
          >
            <MenuItem value="StructureOnly">Structure Only</MenuItem>
            <MenuItem value="ContentWithMembers">
              Structure + Members + Content
            </MenuItem>
          </Select>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!name || !copyStyle || copyStatus}
          onClick={onSubmit}
        >
          Copy
        </Button>
      </CardActions>
    </Card>
  );
};
