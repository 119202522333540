import {
  TextField,
  Datagrid,
  ReferenceField,
  NumberField,
  InfiniteList,
  InfiniteListProps,
} from "react-admin";
import PersonField from "./PersonField";

export const TenantList = (props: InfiniteListProps) => {
  return (
    <InfiniteList
      {...props}
      perPage={25}
      sort={{ field: "Name", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="Name" />
        <TextField source="Domain" />
        <TextField source="Identifier" />
        <TextField source="Provider" />
        <ReferenceField label="Admin" reference="users" source="AdminId">
          <PersonField />
        </ReferenceField>
        <NumberField label="Users" source="UsersCount" />
      </Datagrid>
    </InfiniteList>
  );
};
