import {
  Show,
  ShowProps,
  TextField,
  TopToolbar,
  DeleteButton,
  EditButton,
  SimpleShowLayout,
  useRecordContext,
  useResourceContext,
} from "react-admin";

const IntegrationActions = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <TopToolbar>
      <EditButton resource={resource} record={record}></EditButton>
      <DeleteButton
        resource={resource}
        record={record}
        mutationMode="pessimistic"
      ></DeleteButton>
    </TopToolbar>
  );
};
export const IntegrationShow = (props: ShowProps) => {
  return (
    <Show actions={<IntegrationActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="label" />
      </SimpleShowLayout>
    </Show>
  );
};
