import {
  Show,
  ShowProps,
  TextField,
  BooleanField,
  ReferenceField,
  NumberField,
  useRecordContext,
  Tab,
  TabbedShowLayout,
  Datagrid,
  TopToolbar,
  DeleteButton,
  EditButton,
  List,
  useResourceContext,
} from "react-admin";
import { GroupField } from "./GroupField";

const TemplateActions = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <TopToolbar>
      <EditButton resource={resource} record={record}></EditButton>
      <DeleteButton
        resource={resource}
        record={record}
        mutationMode="pessimistic"
      ></DeleteButton>
    </TopToolbar>
  );
};

const TemplateGroupList = () => {
  const record = useRecordContext();
  return (
    <List
      resource="groups"
      filter={{
        ProjectType_eq: record.Industry,
        ProjectName_eq: record.ProjectName,
      }}
    >
      <Datagrid rowClick="show">
        <GroupField></GroupField>
      </Datagrid>
    </List>
  );
};

export const TemplateShow = (props: ShowProps) => {
  return (
    <Show actions={<TemplateActions />} {...props}>
      <TabbedShowLayout>
        <Tab label="Details">
          <TextField source="id" />
          <NumberField source="Sequence" />
          <TextField source="Industry" />
          <ReferenceField source="GroupId" reference="groups" link="show">
            <GroupField />
          </ReferenceField>
          <TextField source="Description" />
          <BooleanField source="Enabled" />
          <BooleanField source="Sample" />
        </Tab>
        <Tab label="Groups" path="Groups">
          <TemplateGroupList></TemplateGroupList>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
