import {
  Show,
  ShowProps,
  TextField,
  useShowController,
  ReferenceField,
  SimpleShowLayout,
  ReferenceManyField,
  SingleFieldList,
} from "react-admin";

import { IdpGroupField } from "./IDP_GroupField";
import GroupField from "./GroupField";

export const IDP_GroupShow = (props: ShowProps) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  return (
    <Show title={`Identity Provider Group ${record?.DisplayName}`} {...props}>
      <SimpleShowLayout>
        <IdpGroupField />
        <ReferenceField
          label="Tenant"
          reference="tenants"
          source="TenantId"
          link="show"
        >
          <TextField source="Name" />
        </ReferenceField>
        <ReferenceManyField
          label="Groups"
          reference="groups"
          target="Member"
          filter={{ parent: "idp_groups" }}
        >
          <SingleFieldList linkType="show">
            <GroupField />
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
