import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceField,
  CreateProps,
} from "react-admin";
import { useLocation } from "react-router-dom";
import { GroupField } from "./GroupField";

export const TemplateCreate = (props: CreateProps) => {
  // Read the post_id from the location
  const location = useLocation();
  const state = location.state as { record: { GroupId: string } };
  const GroupId = state?.record?.GroupId;
  return (
    <Create {...props}>
      <SimpleForm defaultValues={{ GroupId: GroupId, Sequence: 9999 }}>
        <ReferenceField source="GroupId" reference="groups" link="show">
          <GroupField />
        </ReferenceField>
        <TextInput source="id" />
        <TextInput source="Industry" />
        <TextInput source="Description" />
        <BooleanInput source="Enabled" />
      </SimpleForm>
    </Create>
  );
};
