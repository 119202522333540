import {
  ArrayInput,
  Edit,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  FormDataConsumer,
  required,
  EditProps,
} from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { v4 as uuidv4 } from "uuid";
import { useFormContext } from "react-hook-form";
import { Permissions } from "./Permissions";

interface SecretEditProps {
  source: string;
  secret?: string;
  onClick: () => void;
}

const SecretEdit = (props: SecretEditProps) => {
  const { source, secret, onClick } = props;
  const { setValue } = useFormContext();
  useEffect(() => {
    if (secret) {
      setValue(source, secret, { shouldDirty: true });
    }
  }, [secret, setValue, source]);
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      Set Client Secret
    </Button>
  );
};

export const ApplicationEdit = (props: EditProps) => {
  const [open, setOpen] = useState(false);
  const [secret, setSecret] = useState("");
  const [clientSecret, setClientSecret] = useState<string>();

  const generateSecret = () => {
    setSecret(uuidv4());
  };

  const clientSources = [
    { id: "Groopit", name: "Groopit" },
    { id: "Slack", name: "Slack" },
    { id: "Salesforce", name: "Salesforce" },
    { id: "Teams", name: "Teams" },
    { id: "Outlook", name: "Outlook" },
  ];

  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="DisplayName" />
          <TextInput source="ClientId" />
          <SelectInput
            validate={required()}
            source="ClientSource"
            defaultValue="Slack"
            choices={clientSources}
            disableValue="not_available"
          />
          <SelectInput
            validate={required()}
            source="Type"
            choices={[
              { id: "confidential", name: "Confidential" },
              { id: "public", name: "Public" },
            ]}
          />
          <FormDataConsumer>
            {({ formData }) =>
              formData.Type === "confidential" && (
                <SecretEdit
                  source="ClientSecret"
                  secret={clientSecret}
                  onClick={() => setOpen(true)}
                />
              )
            }
          </FormDataConsumer>
          <ArrayInput source="RedirectUris">
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="PostLogoutRedirectUris">
            <SimpleFormIterator>
              <TextInput source="" />
            </SimpleFormIterator>
          </ArrayInput>
          <CheckboxGroupInput
            source="Permissions"
            choices={Permissions}
          ></CheckboxGroupInput>
        </SimpleForm>
      </Edit>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reset Secret</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Warning! Setting the client secret will prevent any clients using
            the old secret from ever logging in again. This is irreversible!
          </DialogContentText>
          <DialogContentText>
            Make sure you have a backup of the client secret before setting it,
            as you will not be able to recover it if you lose it.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Client Secret"
            value={secret}
            fullWidth
            onChange={(e) => setSecret(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => navigator.clipboard.writeText(secret)}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => generateSecret()} color="primary">
            Generate New Secret
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setClientSecret(secret);
              setOpen(false);
            }}
            color="secondary"
          >
            Set Secret
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
