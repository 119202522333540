import { Avatar, Box, Typography } from "@mui/material";
import { useRecordContext } from "react-admin";
import { useTheme } from "@mui/material/styles";

export const IdpGroupField = () => {
  const record = useRecordContext();
  const theme = useTheme();
  return record && record.id !== "0" ? (
    <Box
      sx={{ display: "flex", alignItems: "center", width: "500px", margin: 1 }}
    >
      <Avatar
        variant="square"
        src="/Content/Images/idpgroup.png"
        sx={{
          marginRight: 1,
          width: theme.spacing(7),
          height: theme.spacing(7),
        }}
      />
      {record.error ? (
        <div>
          <Typography variant="h6">(deleted {record.error.status})</Typography>
          <Typography variant="caption">
            <div>{record.id}</div>
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h6">{record.DisplayName}</Typography>
          <Typography variant="caption">
            <div>{record.Description} </div>
          </Typography>
        </div>
      )}
    </Box>
  ) : (
    <div></div>
  );
};
