import {
  InteractionRequiredAuthError,
  PublicClientApplication,
} from "@azure/msal-browser";
import { AuthProvider } from "react-admin";

export interface AzureAuthProvider extends AuthProvider {
  getAccessToken: (scopes: string[]) => Promise<string>;
}

const azure_ad_auth_provider = (
  pca: PublicClientApplication,
): AzureAuthProvider => {
  const getAccount = () => {
    let account = pca.getActiveAccount();
    if (!account) {
      const accounts = pca.getAllAccounts();
      if (accounts.length === 0) {
        return null;
      }
      account = accounts[0];
      pca.setActiveAccount(account);
    }
    return account;
  };
  const _getAccessToken = async (scopes: string[]) => {
    const account = getAccount();
    if (!account) {
      return Promise.reject("No account was logged in");
    }
    try {
      const silentResult = await pca.acquireTokenSilent({ account, scopes });
      return silentResult.accessToken;
    } catch (err) {
      if (err instanceof InteractionRequiredAuthError) {
        await pca.acquireTokenRedirect({
          account,
          scopes,
        });
        return "";
      } else {
        throw err;
      }
    }
  };

  return {
    login: () => {
      console.log("logging in");

      return Promise.resolve();
    },
    logout: () => {
      console.log("authprovider: logout() called");

      if (!pca.getActiveAccount()) {
        /*
         * User is not signed in. Throw error or wait for user to login.
         * Do not attempt to log a user in outside of the context of MsalProvider
         */
        console.log("authprovider: logout() not logged in");
        return Promise.resolve();
      }
      console.log("authprovider: logout() doing logout");
      return pca.logout();
    },
    checkAuth: async () => {
      const account = getAccount();
      if (!account) {
        return Promise.reject("no account is logged in");
      }
    },
    checkError: (error) => {
      console.log(
        `authprovider: checkError called, status ${error.status}\n error is ${error}`,
      );
      if (error.status === 401) {
        return Promise.reject();
      }
      return Promise.resolve();
    },
    getIdentity: async () => {
      // let account = pca.getActiveAccount();
      // if (!account) {
      //     const accounts = pca.getAllAccounts();
      //     if (accounts.length === 0) {
      //         return Promise.reject();
      //     }
      //     account = accounts[0];
      //     pca.setActiveAccount(account);
      //}
      const account = getAccount();
      if (!account) {
        return Promise.reject("No account was logged in");
      }
      const token = await _getAccessToken([
        "https://graph.microsoft.com/User.Read",
      ]);
      const response = await fetch(
        "https://graph.microsoft.com/v1.0/me/photo/$value",
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        },
      );
      const blob = await response.blob();
      const uri = URL.createObjectURL(blob);

      return Promise.resolve({
        avatar: uri,
        id: account.username,
        fullName: account.name,
      });
    },
    getPermissions: () => Promise.resolve(),
    getAccessToken: async (scopes) => _getAccessToken(scopes),
  };
};

export default azure_ad_auth_provider;
