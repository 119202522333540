//
// Definition of the various permissions that can be granted an application.
// See https://github.com/openiddict/openiddict-core/blob/dev/src/OpenIddict.Abstractions/OpenIddictConstants.cs
// for the full list of permissions.
//
export const Permissions = [
  { id: "ept:authorization", name: "Endpoint:authorization" },
  { id: "ept:device", name: "Endpoint:device" },
  { id: "ept:introspection", name: "Endpoint:introspection" },
  { id: "ept:logout", name: "Endpoint:logout" },
  { id: "ept:revocation", name: "Endpoint:revocation" },
  { id: "ept:token", name: "Endpoint:token" },
  { id: "gt:authorization_code", name: "GrantType:authorization_code" },
  { id: "gt:client_credentials", name: "GrantType:client_credentials" },
  {
    id: "gt:urn:ietf:params:oauth:grant-type:device_code",
    name: "GrantType:device_code",
  },
  { id: "gt:implicit", name: "GrantType:implicit" },
  { id: "gt:password", name: "GrantType:password" },
  { id: "gt:refresh_token", name: "GrantType:refresh_token" },
  { id: "rst:code", name: "ResponseType:code" },
  { id: "rst:code id_token", name: "ResponseType:code id_token" },
  { id: "rst:code id_token token", name: "ResponseType:code id_token token" },
  { id: "rst:code token", name: "ResponseType:code token" },
  { id: "rst:id_token", name: "ResponseType:id_token" },
  { id: "rst:id_token token", name: "ResponseType:id_token token" },
  { id: "rst:none", name: "ResponseType:none" },
  { id: "rst:token", name: "ResponseType:token" },
  { id: "rst:cookie", name: "ResponseType:cookie" },
  { id: "scp:address", name: "Scope:address" },
  { id: "scp:email", name: "Scope:email" },
  { id: "scp:phone", name: "Scope:phone" },
  { id: "scp:profile", name: "Scope:profile" },
  { id: "scp:roles", name: "Scope:roles" },
];
