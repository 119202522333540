import {
  List,
  ListProps,
  Datagrid,
  DateField,
  TextField,
  ReferenceField,
} from "react-admin";
import { PersonField } from "./PersonField";
import { GroupField } from "./GroupField";

export const ActivityList = (props: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      perPage={25}
      sort={{ field: "CreationTime", order: "DESC" }}
    >
      <Datagrid rowClick="show">
        <DateField source="CreationTime" />
        <TextField source="Event" />
        <ReferenceField
          label="Group"
          source="GroupId"
          reference="groups"
          link="show"
        >
          <GroupField />
        </ReferenceField>
        <ReferenceField
          label="User"
          source="UserId"
          reference="users"
          link="show"
        >
          <PersonField />
        </ReferenceField>

        <TextField source="TargetObject" />
      </Datagrid>
    </List>
  );
};
