import {
  Datagrid,
  BooleanField,
  EmailField,
  NumberField,
  DateField,
  Filter,
  TextInput,
  useListContext,
  TopToolbar,
  ExportButton,
  ReferenceManyField,
  SingleFieldList,
  Show,
  CreateButton,
  InfiniteList,
  InfiniteListProps,
  RaRecord,
  Identifier,
} from "react-admin";
import GroupField from "./GroupField";
import { PersonField } from "./PersonField";

const UserFilter = () => (
  <Filter>
    <TextInput label="Name" source="Name" alwaysOn />
    <TextInput label="Email" source="Email" alwaysOn />
  </Filter>
);

const ListActions = () => {
  const { sort, resource, filterValues, total } = useListContext();
  return (
    <TopToolbar>
      <CreateButton />
      <ExportButton
        disabled={total === 0}
        resource={resource}
        sort={sort}
        filterValues={filterValues}
      />
    </TopToolbar>
  );
};

const GroupPanel = (props: {
  id: Identifier;
  record: RaRecord;
  resource: string;
}) => (
  <Show actions={false} title=" " {...props}>
    <ReferenceManyField
      {...props}
      label="member"
      reference="groups"
      target="Member"
      filter={{ parent: "users" }}
    >
      <SingleFieldList linkType="show">
        <GroupField />
      </SingleFieldList>
    </ReferenceManyField>
  </Show>
);
export const UserList = (props: InfiniteListProps) => {
  return (
    <InfiniteList
      {...props}
      perPage={25}
      filters={<UserFilter />}
      actions={<ListActions />}
      sort={{ field: "CreationDate", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        isRowExpandable={(r) => r.MemberCount > 0}
        expand={GroupPanel}
      >
        <PersonField label="Name" />
        <EmailField source="Email" />
        <DateField source="CreationDate" showTime />
        <NumberField source="OwnedCount" />
        <NumberField source="MemberCount" />
        <BooleanField source="EmailConfirmed" />
      </Datagrid>
    </InfiniteList>
  );
};
