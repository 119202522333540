import {
  Show,
  ShowProps,
  TextField,
  TopToolbar,
  DeleteButton,
  EditButton,
  SimpleShowLayout,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  SelectField,
  useShowController,
  BooleanField,
  useRecordContext,
  useResourceContext,
  Pagination,
  CreateButton,
} from "react-admin";
import PersonField from "./PersonField";
import { GroupField } from "./GroupField";
import { IdpGroupField } from "./IDP_GroupField";

const TenantActions = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <TopToolbar>
      <CreateButton
        resource="groups"
        title="Create Group"
        label="Create Group"
        state={{ record: { TenantId: record?.id } }}
      ></CreateButton>
      <EditButton resource={resource} record={record}></EditButton>
      <DeleteButton
        resource={resource}
        record={record}
        mutationMode="pessimistic"
      ></DeleteButton>
    </TopToolbar>
  );
};

export const TenantShow = (props: ShowProps) => {
  const { record } = useShowController(props);

  return (
    <Show
      title={`Tenant ${record.Name}`}
      actions={<TenantActions />}
      {...props}
    >
      <SimpleShowLayout>
        <TextField source="Name" />
        <TextField source="Identifier" />
        <ReferenceField
          label="Admin"
          reference="users"
          source="AdminId"
          link="show"
        >
          <PersonField />
        </ReferenceField>
        {record?.IsDisabled && (
          <BooleanField label="Disabled" source="IsDisabled" />
        )}
        {record?.Domain && <TextField label="Email Domain" source="Domain" />}
        <SelectField
          source="SubscriptionLevel"
          choices={[
            { id: "Free", name: "Individual" },
            { id: "Professional", name: "Professional" },
            { id: "Enterprise", name: "Enterprise" },
          ]}
        ></SelectField>
        {record?.SubscriptionId && (
          <ReferenceField
            source="SubscriptionId"
            reference="subscriptions"
            link="show"
          >
            <TextField source="id" />
          </ReferenceField>
        )}
        <SelectField
          source="Provider"
          choices={[
            { id: "Groopit", name: "Groopit" },
            { id: "AzureAD", name: "AzureAD (Microsoft 365)" },
            { id: "Google", name: "Google" },
            { id: "Okta", name: "Okta" },
            { id: "OneLogin", name: "OneLogin" },
          ]}
        />
        <ReferenceManyField
          label="Users"
          reference="users"
          target="TenantId"
          perPage={50}
          pagination={<Pagination rowsPerPageOptions={[]} />}
        >
          <SingleFieldList linkType="show">
            <PersonField />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField label="Groups" reference="groups" target="TenantId">
          <SingleFieldList linkType="show">
            <GroupField />
          </SingleFieldList>
        </ReferenceManyField>
        <ReferenceManyField
          label="Identity Provider Groups"
          reference="idp_groups"
          target="TenantId"
        >
          <SingleFieldList linkType="show">
            <IdpGroupField />
          </SingleFieldList>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};
