import { Avatar, Box, Typography } from "@mui/material";
import { UseRecordContextParams, useRecordContext } from "react-admin";
import { useTheme } from "@mui/material/styles";

export const GroupField = (props: UseRecordContextParams) => {
  const record = useRecordContext(props);
  const theme = useTheme();
  return record && record.id !== "00000000-0000-0000-0000-000000000000" ? (
    <Box
      sx={{ display: "flex", alignItems: "center", width: "500px", margin: 1 }}
    >
      <Avatar
        variant="square"
        src={record.Avatar}
        sx={{
          marginRight: 1,
          width: theme.spacing(7),
          height: theme.spacing(7),
        }}
      />
      {record.error ? (
        <div>
          <Typography variant="h6">(deleted {record.error.status})</Typography>
          <Typography variant="caption">
            <div>{record.id}</div>
          </Typography>
        </div>
      ) : (
        <div>
          <Typography variant="h6">{record.Name}</Typography>
          <Typography variant="caption">
            <div>{record.MemberCount} Users</div>
            <div>{record.GroopitCount} Posts</div>
          </Typography>
        </div>
      )}
    </Box>
  ) : (
    <div></div>
  );
};

export default GroupField;
