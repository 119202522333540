import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceField,
  EditProps,
} from "react-admin";
import { GroupField } from "./GroupField";

export const TemplateEdit = (props: EditProps) => {
  // Read the post_id from the location

  return (
    <Edit {...props}>
      <SimpleForm>
        <ReferenceField source="GroupId" reference="groups" link="show">
          <GroupField />
        </ReferenceField>
        <TextInput source="id" />
        <TextInput source="Industry" />
        <TextInput source="Description" />
        <BooleanInput source="Enabled" />
        <BooleanInput source="Sample" />
      </SimpleForm>
    </Edit>
  );
};
