import { useState } from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Select,
  SelectProps,
  MenuItem,
  InputLabel,
  Button,
  TextField,
} from "@mui/material";
import {
  Title,
  Loading,
  useGetOne,
  useGetManyReference,
  useCreate,
  useNotify,
  useRedirect,
  RaRecord,
} from "react-admin";
import { useParams } from "react-router-dom";
import GroupField from "./GroupField";

interface OwnerPickerProps extends SelectProps {
  groupId: string;
}

const OwnerPicker = (props: OwnerPickerProps) => {
  const { groupId, ...selectProps } = props;
  const params = {
    target: "Owners",
    id: groupId,
    pagination: { page: 1, perPage: 100 },
    sort: { field: "Name", order: "ASC" as "ASC" | "DESC" },
    parent: "groups",
  };

  const { data, isLoading, error } = useGetManyReference("users", params);
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <>
      <InputLabel id="owner-picker">Subscription Owner</InputLabel>
      <Select labelId="owner-picker" disabled={isLoading} {...selectProps}>
        <MenuItem value="None">
          <em>None</em>
        </MenuItem>
        {!isLoading &&
          data &&
          data.map((item: RaRecord) => (
            <MenuItem key={item.id} value={item.id}>
              {item.Name}
            </MenuItem>
          ))}
      </Select>
    </>
  );
};

export const CreateSubscription = () => {
  const { id: groupId } = useParams<{ id: string }>();
  if (!groupId) {
    throw new Error("Missing group id");
  }
  const { data, isLoading, error } = useGetOne("groups", { id: groupId });
  const [create, createStatus] = useCreate("subscriptions");
  const [owner, setOwner] = useState("None");
  const [level, setLevel] = useState("Free");
  const [period, setPeriod] = useState("Yearly");
  const [coupon, setCoupon] = useState("");
  const [freeTrial, setFreeTrial] = useState(0);
  const [newEnterpriseName, setNewEnterpriseName] = useState("");
  const notify = useNotify();
  const redirect = useRedirect();

  const cursor = createStatus.isLoading ? "wait" : "default";

  const oncreate = () => {
    const promise = create(
      "subscriptions",
      {
        data: {
          groupId,
          owner,
          level,
          period,
          coupon,
          freeTrial,
          newEnterpriseName,
        },
      },
      { returnPromise: true },
    );

    promise
      .then((record) => {
        redirect(`/groups/${record.data.groupId}/show`);
        notify("Subscription created successfully");
      })
      .catch((error) => {
        // failure side effects go here
        notify(`Subscription creation failure error: ${error.message}`, {
          type: "warning",
        });
      });
  };

  if (isLoading) {
    return <Loading />;
  }
  if (error) {
    return <p>ERROR</p>;
  }

  return (
    <Card style={{ cursor: cursor }}>
      <Title title="Create Subscription" />
      <CardHeader title="Create a new subscription for this group"></CardHeader>
      <CardContent>
        <GroupField record={data} />
        <FormControl>
          <OwnerPicker
            groupId={groupId}
            value={owner}
            onChange={(e) => setOwner(e.target.value as string)}
          />
        </FormControl>
        <FormControl>
          <InputLabel id="level-picker">Subscription Level</InputLabel>
          <Select
            labelId="level-picker"
            disabled={owner === "None"}
            value={owner === "None" ? "Free" : level}
            onChange={(e) => setLevel(e.target.value as string)}
          >
            <MenuItem value="Free">Free</MenuItem>
            <MenuItem value="Essential">Essential</MenuItem>
            <MenuItem value="Professional">Professional</MenuItem>
            <MenuItem value="Enterprise">Enterprise</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="billing-period">Subscription Level</InputLabel>
          <Select
            labelId="billing-period"
            disabled={owner === "None"}
            value={period}
            onChange={(e) => setPeriod(e.target.value as string)}
          >
            <MenuItem value="Monthly">Monthly</MenuItem>
            <MenuItem value="Yearly">Yearly</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <TextField
            disabled={owner === "None"}
            value={freeTrial}
            label="Free Trial (days)"
            type="number"
            onChange={(e) => setFreeTrial(parseInt(e.target.value))}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            disabled={owner === "None"}
            value={coupon}
            label="Coupon Code"
            onChange={(e) => setCoupon(e.target.value.toUpperCase() as string)}
          ></TextField>
        </FormControl>
        {level === "Enterprise" && data && !data.IsParent && (
          <FormControl>
            <br />
            <p>
              If you enter a new Enterprise group name, then a new Enterprise
              group will be created and this group will become a linked group to
              that Enterprise. If you leave blank, then the current group will
              be upgraded to Enterprise.
            </p>
            <TextField
              sx={{ minWidth: 500 }}
              disabled={owner === "None"}
              value={newEnterpriseName}
              label="New Enterprise Group Name"
              inputProps={{ maxLength: 64 }}
              onChange={(e) => setNewEnterpriseName(e.target.value as string)}
            ></TextField>
          </FormControl>
        )}
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          disabled={owner === "None" || createStatus.isLoading}
          onClick={oncreate}
        >
          Create
        </Button>
      </CardActions>
    </Card>
  );
};
