import {
  Show,
  ShowProps,
  TextField,
  TopToolbar,
  DeleteButton,
  EditButton,
  SimpleShowLayout,
  ArrayFieldProps,
  useRecordContext,
  useResourceContext,
} from "react-admin";
import { Chip } from "@mui/material";

const ApplicationActions = () => {
  const record = useRecordContext();
  const resource = useResourceContext();
  return (
    <TopToolbar>
      <EditButton resource={resource} record={record}></EditButton>
      <DeleteButton
        resource={resource}
        record={record}
        mutationMode="pessimistic"
      ></DeleteButton>
    </TopToolbar>
  );
};

const TextArrayField = (props: Omit<ArrayFieldProps, "children">) => {
  const record = useRecordContext(props);
  const { source } = props;
  const array: string[] = source ? record[source] : [];
  if (typeof array === "undefined" || array === null || array.length === 0) {
    return <div />;
  } else {
    return (
      <>
        {array.map((item) => (
          <Chip label={item} key={item} />
        ))}
      </>
    );
  }
};

export const ApplicationShow = (props: ShowProps) => {
  return (
    <Show actions={<ApplicationActions />} {...props}>
      <SimpleShowLayout>
        <TextField source="DisplayName" />
        <TextField source="ClientId" />
        <TextField source="Type" />
        <TextArrayField source="RedirectUris" />
        <TextArrayField source="PostLogoutRedirectUris" />
        <TextArrayField source="Permissions" />
      </SimpleShowLayout>
    </Show>
  );
};
