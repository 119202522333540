import {
  ArrayInput,
  Create,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
  SelectInput,
  CheckboxGroupInput,
  FormDataConsumer,
  required,
  CreateProps,
} from "react-admin";
import { IconButton, InputAdornment } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { v4 as uuidv4 } from "uuid";
import { useMemo, useState } from "react";
import { Permissions } from "./Permissions";

export const ApplicationCreate = (props: CreateProps) => {
  const initialValue = useMemo(() => uuidv4(), []);
  const [secret, setSecret] = useState(initialValue);

  const clientSources = [
    { id: "Groopit", name: "Groopit" },
    { id: "Slack", name: "Slack" },
    { id: "Salesforce", name: "Salesforce" },
    { id: "Teams", name: "Teams" },
    { id: "Outlook", name: "Outlook" },
  ];

  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="DisplayName" />
        <TextInput label="Client ID" source="ClientId" />
        <SelectInput
          validate={required()}
          source="ClientSource"
          defaultValue="Slack"
          choices={clientSources}
        />
        <SelectInput
          validate={required()}
          source="Type"
          defaultValue="confidential"
          choices={[
            { id: "confidential", name: "Confidential" },
            { id: "public", name: "Public" },
          ]}
        />
        <FormDataConsumer>
          {({ formData }) =>
            formData.Type === "confidential" && (
              <TextInput
                source="ClientSecret"
                defaultValue={initialValue}
                onChange={(e) => setSecret(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        color="primary"
                        onClick={() => navigator.clipboard.writeText(secret)}
                      >
                        <FileCopyOutlinedIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )
          }
        </FormDataConsumer>
        <ArrayInput source="RedirectUris">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="PostLogoutRedirectUris">
          <SimpleFormIterator>
            <TextInput source="" />
          </SimpleFormIterator>
        </ArrayInput>
        <CheckboxGroupInput
          source="Permissions"
          choices={Permissions}
        ></CheckboxGroupInput>
      </SimpleForm>
    </Create>
  );
};
