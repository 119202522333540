import {
  List,
  ListProps,
  Datagrid,
  EmailField,
  TextField,
  ArrayField,
  ChipField,
  SingleFieldList,
  ReferenceField,
  useRecordContext,
  DateField,
} from "react-admin";
import { Link } from "react-router-dom";
import GroupField from "./GroupField";

const ConditionalGroupField = () => {
  const record = useRecordContext();
  return record ? (
    record.groupId ? (
      <ReferenceField
        record={record}
        label="Group"
        source="groupId"
        reference="groups"
        link="show"
      >
        <GroupField />
      </ReferenceField>
    ) : (
      <Link
        onClick={(e) => {
          e.stopPropagation();
        }}
        to={`/AssignSubscription/${record.id}`}
      >
        Assign to group
      </Link>
    )
  ) : null;
};

export const SubscriptionList = (props: ListProps) => (
  <List {...props}>
    <Datagrid bulkActionButtons={false} rowClick="show">
      <TextField source="id" />
      <TextField source="status" />
      base
      <ConditionalGroupField />
      <EmailField source="customerEmail" />
      <DateField label="Created" source="startDate" />
      <DateField label="Renews" source="currentPeriodEnd" />
      <ArrayField label="Product" source="products">
        <SingleFieldList linkType={false}>
          <ChipField source="planNickname" />
        </SingleFieldList>
      </ArrayField>
    </Datagrid>
  </List>
);
