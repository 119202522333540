import {
  List,
  ListProps,
  Datagrid,
  TextField,
  ReferenceField,
} from "react-admin";
import GroupField from "./GroupField";

export const IntegrationList = (props: ListProps) => (
  <List bulkActionButtons={false} {...props}>
    <Datagrid rowClick="show">
      <ReferenceField
        label="Group"
        source="groupId"
        reference="groups"
        link="show"
      >
        <GroupField />
      </ReferenceField>
      <TextField source="label" />
    </Datagrid>
  </List>
);
