import {
  useGetList,
  useRecordContext,
  EmailField,
  ResourceContextProvider,
  RecordContextProvider,
  EmailFieldProps,
} from "react-admin";
import { Link, Chip, Avatar, Tooltip } from "@mui/material";
import { Alert } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/material";

export const PersonField = (props: { label?: string }) => {
  const record = useRecordContext(props);
  return record ? (
    <Box>
      <Tooltip title={record.Email}>
        <Chip
          sx={{ margin: "4px" }}
          label={
            record.error ? `(deleted ${record.error.status})` : record.Name
          }
          avatar={<Avatar src={record.Avatar} />}
        />
      </Tooltip>
    </Box>
  ) : null;
};

//
// Just like PersonField, but it takes an email field as a source
// property and then looks that user up by email
//
export const EmailPerson = (props: EmailFieldProps) => {
  const { source } = props;
  const record = useRecordContext(props);
  const { data, isLoading, error } = useGetList("users", {
    pagination: { page: 1, perPage: 5 },
    sort: { field: "email", order: "ASC" },
    filter: { email: record[source ?? ""] },
  });
  if (isLoading || error || !data) {
    return <EmailField {...props}></EmailField>;
  }
  if (data.length === 0) {
    return (
      <div>
        <Alert severity="error">User was not found!</Alert>
        <EmailField {...props}></EmailField>
      </div>
    );
  }
  return (
    <ResourceContextProvider value="users">
      <RecordContextProvider value={data[0]} key={data[0].id}>
        <Link component={RouterLink} to={"/users/" + data[0].id + "/show"}>
          <PersonField />
        </Link>
      </RecordContextProvider>
    </ResourceContextProvider>
  );
};

export default PersonField;
