import {
  Show,
  ShowProps,
  ReferenceManyField,
  EmailField,
  DateField,
  SingleFieldList,
  useShowController,
  Tab,
  TabbedShowLayout,
  Datagrid,
  ReferenceField,
  TextField,
  ArrayField,
  ChipField,
  BooleanField,
  EditButton,
  TopToolbar,
  Pagination,
  useAuthProvider,
  useRecordContext,
} from "react-admin";
import { Button, Box } from "@mui/material";
import { PersonField } from "./PersonField";
import GroupField from "./GroupField";
import { AzureAuthProvider } from "./azure-ad-authProvider";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const UserShowActions = () => (
  <TopToolbar>
    <EditButton />
  </TopToolbar>
);

const BillingPortalButton = () => {
  const record = useRecordContext();
  const userid = record.id;

  const authProvider = useAuthProvider() as AzureAuthProvider;

  const onClick = () => {
    authProvider
      .getAccessToken(["api://e04ab6e4-8338-42b5-b9e0-7eca9fb58867/Read"])
      .then((token) =>
        fetch(
          `/BillingPortal?userid=${userid}&returnUrl=${encodeURIComponent(
            window.location.href,
          )}`,
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          },
        ),
      )
      .then((response) => response.json())
      .then((j) => (window.location.href = j.url));
  };

  return (
    <Button variant="contained" onClick={onClick}>
      Manage Billing on Stripe
    </Button>
  );
};

export const UserShow = (props: ShowProps) => {
  const { record } = useShowController(props);
  return (
    <Show
      title={<span>User {record ? `"${record.Name}"` : ""}</span>}
      actions={<UserShowActions></UserShowActions>}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Details">
          <PersonField />
          <DateField source="CreationDate" label="Created" showTime />
          <EmailField source="Email" />
          <BooleanField source="EmailConfirmed" />
          {record?.TenantId && (
            <ReferenceField
              label="Tenant"
              reference="tenants"
              source="TenantId"
              link="show"
            >
              <TextField source="Name" />
            </ReferenceField>
          )}
          {record?.IsDisabled && (
            <BooleanField label="Disabled" source="IsDisabled" />
          )}
          {record?.utm_source && (
            <TextField label="utm_source" source="utm_source" />
          )}
          {record?.utm_campaign && (
            <TextField label="utm_campaign" source="utm_campaign" />
          )}
          {record?.utm_medium && (
            <TextField label="utm_medium" source="utm_medium" />
          )}
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Organizer Groups
            </AccordionSummary>

            <AccordionDetails>
              <ReferenceManyField
                label="owned"
                reference="groups"
                target="Owner"
                filter={{ parent: "users" }}
              >
                <SingleFieldList linkType="show">
                  <GroupField />
                </SingleFieldList>
              </ReferenceManyField>
            </AccordionDetails>
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Member groups
            </AccordionSummary>
            <AccordionDetails>
              <ReferenceManyField
                label="member"
                reference="groups"
                target="Member"
                filter={{ parent: "users" }}
              >
                <SingleFieldList linkType="show">
                  <GroupField />
                </SingleFieldList>
              </ReferenceManyField>
            </AccordionDetails>
            A
          </Accordion>
          <Accordion TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              Participant groups
            </AccordionSummary>

            <AccordionDetails>
              <ReferenceManyField
                label="user"
                reference="groups"
                target="Participant"
                filter={{ parent: "users" }}
              >
                <SingleFieldList linkType="show">
                  <GroupField />
                </SingleFieldList>
              </ReferenceManyField>
            </AccordionDetails>
          </Accordion>
        </Tab>
        <Tab label="Activity" path="Activity">
          <ReferenceManyField
            label="activities"
            reference="activities"
            target="UserId"
            pagination={<Pagination />}
            sort={{ field: "CreationTime", order: "DESC" }}
          >
            <Datagrid>
              <DateField source="CreationTime" showTime={true} />
              <TextField source="Event" />
              <ReferenceField
                label="Group"
                source="GroupId"
                reference="groups"
                link="show"
              >
                <GroupField />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Subscription" path="Subscription">
          {record?.BillingId && (
            <Box m={2}>
              <BillingPortalButton />
            </Box>
          )}
          <ReferenceManyField
            label="Subscriptions"
            reference="subscriptions"
            source="BillingId"
            target="CustomerId"
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="status" />
              <DateField source="created" />
              <DateField source="currentPeriodStart" />
              <DateField source="currentPeriodEnd" />
              <ReferenceField
                label="Group"
                source="groupId"
                reference="groups"
                link="show"
              >
                <GroupField />
              </ReferenceField>
              <ArrayField label="product" source="items.data">
                <SingleFieldList linkType={false}>
                  <ChipField source="plan.nickname" />
                </SingleFieldList>
              </ArrayField>
              <DateField source="trialStart" />
              <DateField source="trialEnd" />{" "}
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
