import { useEffect, useState } from "react";
import {
  BooleanInput,
  Edit,
  EditProps,
  SimpleForm,
  EmailField,
  TextInput,
  CreateProps,
  Create,
  ReferenceInput,
  AutocompleteInput,
  TopToolbar,
  Button,
  useAuthProvider,
  ShowButton,
  useNotify,
  useEditContext,
} from "react-admin";
import { AzureAuthProvider } from "./azure-ad-authProvider";

const UserEditActions = () => {
  const { data } = useEditContext();

  const notify = useNotify();
  const authProvider = useAuthProvider() as AzureAuthProvider;
  const [isAdmin, setIsAdmin] = useState<boolean>();
  useEffect(() => {
    if (data) {
      authProvider
        .getAccessToken(["api://e04ab6e4-8338-42b5-b9e0-7eca9fb58867/Read"])
        .then((token) =>
          fetch(`/users/GetAdmin/${data.id}`, {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
            .then((response) => response.json())
            .then((j) => setIsAdmin(j)),
        );
    }
  }, [authProvider, data, data?.id]);

  const makeAdmin = (isAdmin: boolean) => {
    authProvider
      .getAccessToken(["api://e04ab6e4-8338-42b5-b9e0-7eca9fb58867/Read"])
      .then((token) =>
        fetch(`/users/SetAdmin/${data.id}`, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(isAdmin),
        }),
      )
      .then((response) => {
        if (response.status === 200) {
          notify(
            isAdmin
              ? "User is now an admin"
              : "Admin privileges have been removed",
          );
          setIsAdmin(isAdmin);
        } else {
          response.json().then((j) => notify(j.description));
        }
      })
      .catch((error) => {
        notify(error.json().message);
      });
  };
  return (
    <TopToolbar>
      {isAdmin === false && data?.Email.endsWith("@groopit.co") && (
        <Button label="Make Admin" onClick={() => makeAdmin(true)}></Button>
      )}
      {isAdmin && (
        <Button label="Remove Admin" onClick={() => makeAdmin(false)}></Button>
      )}
      <ShowButton />
    </TopToolbar>
  );
};

export const UserEdit = (props: EditProps) => {
  return (
    <Edit {...props} actions={<UserEditActions />}>
      <SimpleForm>
        <TextInput source="Name"></TextInput>
        <EmailField source="Email" />
        <TextInput source="BillingId"></TextInput>
        <BooleanInput source="EmailConfirmed" />
        <BooleanInput label="Disabled" source="IsDisabled" />
        <BooleanInput
          label="Development Access"
          source="FeatureDevelopmentAccess"
        />
        <BooleanInput label="File Field Feature" source="FeatureFileFields" />
        <BooleanInput label="AI Insights Feature" source="FeatureAiInsights" />
        <BooleanInput
          label="AI Suggested Data Models"
          source="FeatureAiSuggestedDataModels"
        />
        <BooleanInput label="River" source="FeatureRiver" />
      </SimpleForm>
    </Edit>
  );
};

export const UserCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="Name"></TextInput>
        <TextInput type="email" source="Email" />
        <ReferenceInput source="TenantId" reference="tenants">
          <AutocompleteInput
            optionText="Name"
            filterToQuery={(searchText) => ({
              Name: [searchText],
            })}
          />
        </ReferenceInput>
        <BooleanInput source="EmailConfirmed" defaultValue={true} />
        <BooleanInput label="Disabled" source="IsDisabled" />
        <BooleanInput label="File Field Feature" source="FeatureFileFields" />
      </SimpleForm>
    </Create>
  );
};
