import {
  Show,
  ShowProps,
  ReferenceManyField,
  ChipField,
  SingleFieldList,
  TabbedShowLayout,
  DateField,
  Tab,
  RichTextField,
  TextField,
  BooleanField,
  useShowController,
  ReferenceField,
  Datagrid,
  EditButton,
  TopToolbar,
  DeleteButton,
  ArrayField,
  Button,
  useRecordContext,
  Pagination,
  NumberField,
  FunctionField,
  useResourceContext,
  ReferenceManyCount,
  useListContext,
} from "react-admin";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { GroupField } from "./GroupField";
import { PersonField, EmailPerson } from "./PersonField";
import { StripeLink } from "./SubscriptionShow";
import { IdpGroupField } from "./IDP_GroupField";

function setAdminCookie() {
  document.cookie = "AdminMode=true;path=/;max-age=3600;domain=groopit.co";
}

const GroupShowActions = () => {
  const record = useRecordContext();
  const resource = useResourceContext();

  return record ? (
    <TopToolbar>
      <Box display="flex" flexGrow={1}>
        <GroupField record={record} />
      </Box>
      <Button
        label="View on Groopit"
        component="a"
        href={`https://app.groopit.co/Groop/GroopIts/${record.id}`}
        onClick={() => {
          setAdminCookie();
        }}
      >
        <img src="/favicon.png" width="16" height="16" alt="" />
      </Button>
      <EditButton resource={resource} record={record} />
      <Button
        label="Create Template"
        disabled={!record?.ProjectName}
        component={Link}
        to={{
          pathname: "/templates/create",
          // Here we specify the initial record for the create view
          state: {
            record: {
              GroupId: record?.id,
              id: record?.Name.trim()
                .replaceAll(" ", "_")
                .replaceAll(/[^\w\d]/g, ""),
            },
          },
        }}
      />
      <Button
        label="Copy"
        component={Link}
        to={{
          pathname: `/groups/${record?.id}/copy`,
          state: {
            record: {
              GroupId: record?.id,
            },
          },
        }}
      />
      <DeleteButton
        record={record}
        resource={resource}
        mutationMode="pessimistic"
      />
    </TopToolbar>
  ) : (
    <></>
  );
};

const AddOwnerButton = () => {
  const record = useRecordContext();
  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        label="+ Add Owner"
        component={Link}
        to={{
          pathname: `/groups/addowner/${record?.id}`,
          // Here we specify the initial record for the create view
          state: {
            record: {
              GroupId: record?.id,
            },
          },
        }}
      />
    </Box>
  );
};

const CreateIntegrationButton = () => {
  const record = useRecordContext();
  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        label="+ Add Integration"
        component={Link}
        to={{
          pathname: `/groups/addintegration/${record?.id}`,
          state: {
            record: {
              GroupId: record?.id,
            },
          },
        }}
      />
    </Box>
  );
};

export const GroupShow = (props: ShowProps) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useShowController(props);
  return (
    <Show
      title={`Group ${record?.Name}`}
      actions={<GroupShowActions />}
      {...props}
    >
      <TabbedShowLayout>
        <Tab label="Details">
          <DateField source="Created" />
          <RichTextField source="Description" />
          <RichTextField source="WhyItMatters" />
          <RichTextField source="HowToContribute" />
          <BooleanField label="Public" source="IsPublic" />
          <BooleanField label="Mask PII" source="MaskPII" />
          <NumberField label="Max Data Models" source="AssignmentLimit" />
          <ReferenceField
            label="Tenant"
            source="TenantId"
            reference="tenants"
            link="show"
          >
            <TextField label="Tenant" source="Name" />
          </ReferenceField>
        </Tab>
        <Tab label="People" path="People">
          <ReferenceManyField
            label="Organizers"
            reference="users"
            target="Owners"
            filter={{ parent: "groups" }}
          >
            <SingleFieldList linkType="show">
              <PersonField />
            </SingleFieldList>
          </ReferenceManyField>
          <AddOwnerButton />
          <ReferenceManyField
            label="Members"
            reference="users"
            target="Members"
            filter={{ parent: "groups" }}
          >
            <SingleFieldList linkType="show">
              <PersonField />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField
            label="Users"
            reference="users"
            target="Participants"
            filter={{ parent: "groups" }}
          >
            <SingleFieldList linkType="show">
              <PersonField />
            </SingleFieldList>
          </ReferenceManyField>
          <ReferenceManyField
            label="Identity Provider Groups"
            reference="idp_groups"
            target="IDP_Groups"
            filter={{ parent: "groups" }}
          >
            <SingleFieldList linkType="show">
              <IdpGroupField />
            </SingleFieldList>
          </ReferenceManyField>
        </Tab>
        {record?.SubscriptionLevel === "Enterprise" && (
          <Tab label="Linked" path="Linked">
            <ReferenceManyField
              label="linked"
              reference="groups"
              target="Linked"
              filter={{ parent: "groups" }}
            >
              <SingleFieldList linkType="show">
                <GroupField></GroupField>
              </SingleFieldList>
            </ReferenceManyField>
          </Tab>
        )}
        <Tab label="Tracking" path="Tracking">
          <TextField source="TrackingType" />
          <TextField source="TrackingSource" />
          <TextField source="TrackingStatus" />
          <TextField source="ProjectType" />
          <TextField source="ProjectName" />
          <TextField source="AdminNotes" />
        </Tab>
        <Tab label="Activity" path="Activity">
          <ReferenceManyField
            label="activities"
            reference="activities"
            target="GroupId"
            pagination={<Pagination />}
            sort={{ field: "CreationTime", order: "DESC" }}
          >
            <Datagrid>
              <DateField source="CreationTime" showTime={true} />
              <TextField source="Event" />
              <ReferenceField
                label="User"
                source="UserId"
                reference="users"
                link="show"
              >
                <PersonField />
              </ReferenceField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Subscription" path="Subscription">
          <ReferenceManyField
            label="Subscriptions"
            reference="subscriptions"
            target="GroupId"
          >
            <Datagrid rowClick="show">
              <TextField source="id" />
              <TextField source="status" />
              <DateField label="Created" source="startDate" />
              <DateField label="Renews" source="currentPeriodEnd" />
              <EmailPerson label="Owner" source="customerEmail" />
              <ArrayField label="Product" source="products">
                <SingleFieldList linkType={false}>
                  <ChipField source="planNickname" />
                </SingleFieldList>
              </ArrayField>
              <DateField source="trialEnd" />
              <StripeLink />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Integrations" path="Integrations">
          <ReferenceManyField
            label="Integrations"
            reference="integrations"
            target="GroupId"
          >
            <Datagrid rowClick="show">
              <TextField source="label" />
            </Datagrid>
          </ReferenceManyField>
          <CreateIntegrationButton />
        </Tab>
        <Tab label="Keyword Prompts" path="KeywordPrompts">
          <ReferenceManyField
            label="Keyword Prompts"
            reference="keywordprompts"
            target="KeywordPrompts"
            filter={{ parent: "groups" }}
          >
            <Datagrid rowClick="edit">
              <ArrayField label="Keywords" source="Keywords">
                <SingleFieldList>
                  <FunctionField<{ keyword: string }>
                    render={(record) => (
                      <ChipField record={record} source="keyword" />
                    )}
                  />
                </SingleFieldList>
              </ArrayField>
              <TextField source="Prompt" />
              <NumberField source="Throttle" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="AI" path="AI">
          <NumberField label="Insights Reports" source="InsightsReportsCount" />
          <ReferenceManyCount
            label="OpenAI Calls"
            reference="ai_prompts"
            target="GroupId"
          ></ReferenceManyCount>
          <ReferenceManyField
            label="OpenAI Costs"
            reference="ai_prompts"
            target="GroupId"
            perPage={undefined}
          >
            <CostTotal></CostTotal>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

const CostTotal = () => {
  const { data } = useListContext();
  if (!data) return <></>;
  const sum = data.reduce((sum, record) => sum + record.Cost, 0);
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  });
  return <div>{formatter.format(sum)}</div>;
};
