import {
  List,
  ListProps,
  TextField,
  ReferenceField,
  BooleanField,
  NumberField,
} from "react-admin";
import { GroupField } from "./GroupField";
import DraggableDataGrid from "./DraggableDataGrid";

export const TemplateList = (props: ListProps) => {
  return (
    <List {...props} perPage={25} sort={{ field: "Sequence", order: "ASC" }}>
      <DraggableDataGrid rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <NumberField source="Sequence" />
        <TextField source="Industry" />
        <ReferenceField source="GroupId" reference="groups" link="show">
          <GroupField />
        </ReferenceField>
        <TextField source="Description" />
        <BooleanField source="Enabled" />
        <BooleanField source="Sample" />
      </DraggableDataGrid>
    </List>
  );
};
