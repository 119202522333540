import GroupField from "./GroupField";
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControl,
  Button,
  TextField,
} from "@mui/material";
import { IconButton, InputAdornment } from "@mui/material";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { v4 as uuidv4 } from "uuid";
import { useMemo, useState } from "react";
import { Title, Loading, useGetOne, useCreate } from "react-admin";
import { useParams } from "react-router-dom";

export const IntegrationCreate = () => {
  const { id: groupId } = useParams<{ id: string }>();
  const { data, isLoading } = useGetOne("groups", { id: groupId });
  const [create, createStatus] = useCreate("subscriptions");
  const [label, setLabel] = useState("");
  const initialValue = useMemo(() => uuidv4(), []);
  const [token, setToken] = useState(initialValue);

  const cursor = createStatus.isLoading ? "wait" : "default";

  const oncreate = () => {
    create("integrations", {
      data: { groupId, label, token },
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Card style={{ cursor: cursor }}>
      <Title title="Add Integration" />
      <CardHeader title="Create a new subscription for this group"></CardHeader>
      <CardContent>
        <GroupField record={data} />
        <FormControl>
          <TextField
            value={label}
            label="Label"
            onChange={(e) => setLabel(e.target.value)}
          ></TextField>
        </FormControl>
        <FormControl>
          <TextField
            label="Access Token"
            value={initialValue}
            onChange={(e) => setToken(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => navigator.clipboard.writeText(token)}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          ></TextField>
        </FormControl>
      </CardContent>
      <CardActions>
        <Button
          variant="contained"
          color="primary"
          disabled={!label || !token || createStatus.isLoading}
          onClick={oncreate}
        >
          Create
        </Button>
      </CardActions>
    </Card>
  );
};
