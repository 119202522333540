import {
  ArrayField,
  ChipField,
  Edit,
  EditProps,
  FunctionField,
  Identifier,
  NumberInput,
  RaRecord,
  SimpleForm,
  SingleFieldList,
  TextField,
} from "react-admin";

export const KeywordPromptEdit = (props: EditProps) => {
  return (
    <Edit
      redirect={(
        resource?: string,
        id?: Identifier,
        data?: Partial<RaRecord>,
      ) => {
        if (data) {
          return {
            pathname: `/groups/${data[
              "GroopId"
            ].toLowerCase()}/show/KeywordPrompts`,
          };
        } else {
          return {
            pathname: "/groups/list",
          };
        }
      }}
      {...props}
    >
      <SimpleForm>
        <ArrayField label="Keywords" source="Keywords">
          <SingleFieldList>
            <FunctionField<{ keyword: string }>
              render={(record) => (
                <ChipField
                  record={{ keyword: record?.keyword }}
                  source="keyword"
                />
              )}
            />
          </SingleFieldList>
        </ArrayField>
        <TextField source="Prompt" />
        <NumberInput label="Throttle" source="Throttle" />
      </SimpleForm>
    </Edit>
  );
};
