import { Edit, EditProps, SimpleForm, TextInput } from "react-admin";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { v4 as uuidv4 } from "uuid";
import { useFormContext } from "react-hook-form";

interface TokenEditProps {
  source: string;
  token?: string;
  onClick: () => void;
}

const TokenEdit = (props: TokenEditProps) => {
  const { source, token, onClick } = props;
  const { setValue } = useFormContext();
  useEffect(() => {
    if (token) {
      setValue(source, token, { shouldDirty: true });
    }
  });
  return (
    <Button variant="contained" color="secondary" onClick={onClick}>
      Set Access Token
    </Button>
  );
};

export const IntegrationEdit = (props: EditProps) => {
  const [open, setOpen] = useState(false);
  const [token, setToken] = useState("");
  const [accessToken, setAccessToken] = useState<string>();

  const generateToken = () => {
    setToken(uuidv4());
  };
  return (
    <>
      <Edit {...props}>
        <SimpleForm>
          <TextInput source="label" />
          <TokenEdit
            source="token"
            token={accessToken}
            onClick={() => setOpen(true)}
          />
        </SimpleForm>
      </Edit>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Reset Access Token</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Warning! Setting the access token will prevent any clients using the
            old secret from accessing the group. This is irreversible!
          </DialogContentText>
          <DialogContentText>
            Make sure you have a backup of the access token before setting it,
            as you will not be able to recover it if you lose it.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Access Token"
            value={token}
            fullWidth
            onChange={(e) => setToken(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    edge="end"
                    color="primary"
                    onClick={() => navigator.clipboard.writeText(token)}
                  >
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => generateToken()} color="primary">
            Generate New Access Token
          </Button>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => {
              setAccessToken(token);
              setOpen(false);
            }}
            color="secondary"
          >
            Set Access Token
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
