import { Button, Divider, Card, CardContent, CardHeader } from "@mui/material";

import {
  Title,
  useDataProvider,
  useNotify,
  useRedirect,
  Loading,
  TextField,
  SimpleShowLayout,
  useGetOne,
  EmailField,
} from "react-admin";
import { useNavigate, useParams } from "react-router-dom";
import GroupField from "./GroupField";
import { useState } from "react";

const ConfirmSubscription = () => {
  const { sub_id, group_id } = useParams<{
    sub_id: string;
    group_id: string;
  }>();
  if (!sub_id || !group_id) {
    throw new Error("Missing subscription id or group id");
  }
  const nav = useNavigate(); // do this inside the component
  const [btnDisable, setBtnDisable] = useState(false);

  const {
    data: sub,
    isLoading: loading_sub,
    error: error_sub,
  } = useGetOne("subscriptions", { id: sub_id });
  const {
    data: group,
    isLoading: loading_group,
    error: error_group,
  } = useGetOne("groups", { id: group_id });
  const notify = useNotify();
  const redirect = useRedirect();
  const dataProvider = useDataProvider();

  const onConfirm = () => {
    setBtnDisable(true);
    dataProvider
      .update("subscriptions", {
        id: sub_id ?? "",
        data: { GroupId: group_id },
        previousData: sub,
      })
      .then((response) => {
        redirect(`/groups/${response.data.groupId}/show`);
        notify("Subscription assigned successfully");
      })
      .catch((error) => {
        // failure side effects go here
        notify(`Subscription assignment failure error: ${error.message}`, {
          type: "warning",
        });
        setBtnDisable(false);
      });
  };

  if (loading_sub || loading_group) return <Loading />;
  if (error_sub) {
    if (!sub) {
      throw new Error(`Subscription ${sub_id} Not Found`);
    } else {
      throw new Error(`User ${sub.customerEmail} Not Found`);
    }
  }
  if (error_sub) {
    if (!sub) {
      throw new Error(`Subscription ${sub_id} Not Found`);
    } else {
      const e = error_sub as { message: string };
      throw new Error(e.message);
    }
  }
  if (error_group) {
    if (!group) {
      throw new Error(`Group ${group_id} Not Found`);
    } else {
      const e = error_group as { message: string };
      throw new Error(e.message);
    }
  }
  if (!sub || !group) return null;
  return (
    <Card>
      <Title title="Confirm Subscription" />
      <CardHeader title="You are about to assign this subscription to this group"></CardHeader>
      <CardContent>
        <SimpleShowLayout record={sub}>
          <TextField source="id" />
          <EmailField source="customerEmail" />
        </SimpleShowLayout>
        <SimpleShowLayout record={group}>
          <GroupField />
        </SimpleShowLayout>
      </CardContent>
      <Divider variant="middle" />
      <Button onClick={() => nav(-1)}>Cancel</Button>
      <Button disabled={btnDisable} color="primary" onClick={onConfirm}>
        Assign Subscription
      </Button>
    </Card>
  );
};

export default ConfirmSubscription;
