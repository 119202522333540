import { CreateProps, Create } from "react-admin";
import { GroupForm } from "./GroupEdit";

export const GroupCreate = (props: CreateProps) => {
  return (
    <Create {...props}>
      <GroupForm />
    </Create>
  );
};
