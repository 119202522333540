import {
  AutocompleteInput,
  BooleanInput,
  Edit,
  NumberInput,
  ReferenceInput,
  SelectInput,
  ShowProps,
  SimpleForm,
  TextInput,
  email,
  minValue,
  required,
} from "react-admin";

export const GroupForm = () => (
  <SimpleForm>
    <TextInput multiline source="Name" fullWidth validate={required()} />
    <TextInput multiline source="Description" fullWidth />
    <TextInput multiline source="WhyItMatters" fullWidth />
    <TextInput multiline source="HowToContribute" fullWidth />
    <TextInput source="Email" fullWidth validate={[required(), email()]} />
    <BooleanInput label="Public" source="IsPublic" />
    <BooleanInput label="Mask PII" source="MaskPII" />
    <NumberInput
      label="Max Data Models"
      source="AssignmentLimit"
      defaultValue={5}
      validate={[required(), minValue(1, "Must be at least 1")]}
    />
    <ReferenceInput source="TenantId" reference="tenants" fullWidth>
      <AutocompleteInput
        optionText="Name"
        filterToQuery={(searchText) => ({ Name: [searchText] })}
      />
    </ReferenceInput>
    <TextInput source="TrackingType" />
    <SelectInput
      source="TrackingSource"
      choices={[
        { id: "Facebook", name: "Facebook" },
        { id: "Email", name: "Email" },
        { id: "Presentation", name: "Presentation" },
        { id: "Referral", name: "Referral" },
        { id: "Organic", name: "Organic" },
        { id: "Other", name: "Other" },
      ]}
    />
    <SelectInput
      source="TrackingStatus"
      choices={[
        { id: "Started", name: "Started" },
        { id: "InProgress", name: "InProgress" },
        { id: "Operational", name: "Operational" },
        { id: "Duplicate", name: "Duplicate" },
        { id: "Abandoned", name: "Abandoned" },
      ]}
    />
    <SelectInput
      source="ProjectType"
      choices={[
        { id: "Other", name: "Other" },
        { id: "Corporate", name: "Corporate" },
        { id: "Government", name: "Government" },
        { id: "NonProfit", name: "NonProfit" },
        { id: "EverydayLife", name: "EverydayLife" },
        { id: "Healthcare", name: "Healthcare" },
      ]}
    />
    <TextInput source="ProjectName" />
    <TextInput multiline source="AdminNotes" />
    <BooleanInput
      label="Automated Data Insights Capability"
      source="DataInsightsCapability"
    />
  </SimpleForm>
);
export const GroupEdit = (props: ShowProps) => {
  return (
    <Edit {...props}>
      <GroupForm />
    </Edit>
  );
};
