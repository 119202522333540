import { useEffect, useState } from "react";
import {
  Admin,
  CustomRoutes,
  DataProvider,
  Loading,
  Resource,
} from "react-admin";
import odataProvider from "ra-data-odata-server";
import azureAuthProvider from "./azure-ad-authProvider";
import { UserList, UserShow, UserCreate, UserEdit } from "./Users";
import {
  GroupList,
  GroupShow,
  GroupEdit,
  GroupCopy,
  GroupCreate,
} from "./Groups";
import { ActivityList } from "./ActivityList";
import { SubscriptionList } from "./SubscriptionList";
import { SubscriptionShow } from "./SubscriptionShow";
import { TemplateList } from "./TemplateList";
import { TemplateShow } from "./TemplateShow";
import { TemplateCreate } from "./TemplateCreate";
import { TemplateEdit } from "./TemplateEdit";
import { ApplicationList } from "./ApplicationList";
import { ApplicationShow } from "./ApplicationShow";
import { ApplicationCreate } from "./ApplicationCreate";
import { ApplicationEdit } from "./ApplicationEdit";
import { IntegrationList } from "./IntegrationList";
import { IntegrationShow } from "./IntegrationShow";
import { IntegrationEdit } from "./IntegrationEdit";
import { IntegrationCreate } from "./IntegrationCreate";
import { TenantList, TenantShow, TenantCreate, TenantEdit } from "./Tenants";
import { Route } from "react-router-dom";
import AssignSubscription from "./AssignSubscription";
import ConfirmSubscription from "./ConfirmSubscription";
import { AddOwner } from "./AddOwner";
import { CreateSubscription } from "./CreateSubscription";
import { defaultTheme } from "react-admin";

import {
  MsalAuthenticationResult,
  MsalAuthenticationTemplate,
  MsalProvider,
  useAccount,
  useMsal,
} from "@azure/msal-react";
import {
  Configuration,
  InteractionType,
  PublicClientApplication,
} from "@azure/msal-browser";
import { KeywordPromptEdit } from "./KeywordPromptsEdit";
import { IDP_GroupShow } from "./IDP_GroupShow";
import { AIPromptList, AIPromptEdit } from "./AI_Prompt";

const theme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      light: "#9bd8da",
      main: "#06A1A8",
      dark: "#05898F",
    },
    secondary: {
      main: "#D46830",
    },
  },
  components: {
    ...defaultTheme.components,
    MuiFormControl: {
      styleOverrides: {
        root: {
          margin: 1,
          display: "block",
          minWidth: 120,
        },
      },
    },
  },
};

// MSAL configuration
const configuration: Configuration = {
  auth: {
    clientId: "e04ab6e4-8338-42b5-b9e0-7eca9fb58867",
    authority:
      "https://login.microsoftonline.com/4c85f458-1e29-49f6-b0ea-7ccfe5752899",
    postLogoutRedirectUri: "/#/login",
    redirectUri: "/",
    navigateToLoginRequestUrl: false,
  },
};

const pca = new PublicClientApplication(configuration);
const authProvider = azureAuthProvider(pca);

function ErrorComponent({ error }: MsalAuthenticationResult) {
  return <p>An Error Occurred: {error?.errorMessage}</p>;
}

function LoadingComponent() {
  return (
    <Loading
      loadingPrimary="Authentication in progress..."
      loadingSecondary=""
    ></Loading>
  );
}

const App = () => {
  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        loadingComponent={LoadingComponent}
        errorComponent={ErrorComponent}
      >
        <AdminApp></AdminApp>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
};

const AdminApp = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [dataProvider, setDataProvider] = useState<DataProvider>();
  useEffect(() => {
    if (account) {
      authProvider
        .getAccessToken(["api://e04ab6e4-8338-42b5-b9e0-7eca9fb58867/Read"])
        .then((token) =>
          odataProvider(window.location.origin + "/odata", () =>
            Promise.resolve({
              commonHeaders: {
                Authorization: `Bearer ${token}`,
              },
            }),
          ),
        )
        .then((dataProvider) => setDataProvider(dataProvider));
    }
  }, [account, instance]);
  return dataProvider ? (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      theme={theme}
    >
      <CustomRoutes>
        <Route
          path="/AssignSubscription/:id"
          element={<AssignSubscription />}
        />
        <Route
          path="/ConfirmSubscriptionTransfer/:sub_id/:group_id"
          element={<ConfirmSubscription />}
        />
        <Route path="/groups/addowner/:id" element={<AddOwner />} />
        <Route
          path="/groups/createsubscription/:id"
          element={<CreateSubscription />}
        />
        <Route
          path="/groups/addintegration/:id"
          element={<IntegrationCreate />}
        />
        <Route path="/groups/:id/copy" element={<GroupCopy />} />
      </CustomRoutes>
      <Resource
        name="users"
        list={UserList}
        show={UserShow}
        edit={UserEdit}
        create={UserCreate}
      />
      <Resource
        name="groups"
        list={GroupList}
        show={GroupShow}
        edit={GroupEdit}
        create={GroupCreate}
      />
      <Resource name="activities" list={ActivityList} />
      <Resource
        name="subscriptions"
        list={SubscriptionList}
        show={SubscriptionShow}
      />
      <Resource
        name="templates"
        list={TemplateList}
        show={TemplateShow}
        edit={TemplateEdit}
        create={TemplateCreate}
      />
      <Resource
        name="tenants"
        list={TenantList}
        show={TenantShow}
        edit={TenantEdit}
        create={TenantCreate}
      />
      <Resource
        name="applications"
        list={ApplicationList}
        show={ApplicationShow}
        edit={ApplicationEdit}
        create={ApplicationCreate}
      />
      <Resource
        name="integrations"
        list={IntegrationList}
        show={IntegrationShow}
        edit={IntegrationEdit}
      />
      <Resource name="keywordprompts" edit={KeywordPromptEdit} />
      <Resource name="idp_groups" show={IDP_GroupShow} />
      <Resource name="ai_prompts" list={AIPromptList} edit={AIPromptEdit} />
    </Admin>
  ) : (
    <Loading loadingPrimary="Fetching Data" loadingSecondary=""></Loading>
  );
};

export default App;
