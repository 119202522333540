import {
  AutocompleteInput,
  BooleanInput,
  Create,
  CreateProps,
  Edit,
  EditProps,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useEditController,
} from "react-admin";
import { useWatch } from "react-hook-form";

const ProviderData = () => {
  const provider = useWatch({ name: "Provider" });

  if (provider === "Okta" || provider === "OneLogin") {
    return (
      <>
        <TextInput label="Client ID" source="OpenIdConnectClientId" fullWidth />
        <TextInput
          label="Client Secret"
          source="OpenIdConnectClientSecret"
          fullWidth
        />
        <TextInput
          label={`${provider} Domain`}
          source="OpenIdConnectAuthority"
          type="url"
          fullWidth
        />
      </>
    );
  } else if (provider === "AzureAD") {
    return <TextInput label="Azure Tenant ID" source="AzureTenant" fullWidth />;
  }
  return <></>;
};

const TenantForm = () => {
  return (
    <SimpleForm>
      <TextInput source="Name" fullWidth />
      <TextInput source="Identifier" fullWidth />
      <BooleanInput label="Disabled" source="IsDisabled" />
      <ReferenceInput source="AdminId" reference="users" fullWidth>
        <AutocompleteInput
          fullWidth
          optionText="Email"
          filterToQuery={(searchText) => ({ Email: [searchText] })}
        />
      </ReferenceInput>
      <TextInput label="Email Domain" source="Domain" fullWidth />
      <SelectInput
        source="SubscriptionLevel"
        defaultValue="Free"
        validate={required()}
        choices={[
          { id: "Free", name: "Individual" },
          { id: "Essential", name: "Essential" },
          { id: "Professional", name: "Professional" },
          { id: "Enterprise", name: "Enterprise" },
        ]}
        fullWidth
      />
      <SelectInput
        source="Provider"
        defaultValue="Groopit"
        validate={required()}
        choices={[
          { id: "Groopit", name: "Groopit" },
          { id: "AzureAD", name: "AzureAD (Microsoft 365)" },
          { id: "Google", name: "Google" },
          { id: "Okta", name: "Okta" },
          { id: "OneLogin", name: "OneLogin" },
        ]}
        fullWidth
      />
      <ProviderData />
      <TextInput label="Slack Team ID" source="SlackTeamId" />
    </SimpleForm>
  );
};

export const TenantCreate = (props: CreateProps) => {
  // Read the post_id from the location
  return (
    <Create {...props}>
      <TenantForm />
    </Create>
  );
};

export const TenantEdit = (props: EditProps) => {
  const {
    record, // record fetched via dataProvider.getOne() based on the id from the location
  } = useEditController(props);
  return (
    <Edit {...props} title={`Tenant ${record?.Name}`}>
      <TenantForm />
    </Edit>
  );
};
